import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Navigation from "./little_components/navigation";
import Posts from "./little_components/posts"
import NotFoundPage from "./pages/page_notFound";
import Logout from './pages/logout';
import { ProfilePageContainer } from './pages/page_profile'
import OptionsPage from './pages/page_options';
import About from './pages/page_about';
import FriendsPage from './pages/page_friends';
import { Navigate } from 'react-router-dom';
import Commercial from './pages/page_join';
import MessagesPage from './pages/page_messages';
import { WebSocketFunc } from './little_components/websocket_comp'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useRef } from 'react';
import { t } from 'i18next';
import PopupContainer from './little_components/popups_root';
import Zodiac from './pages/page_zodiac'
import ProfileMatrix from './little_components/profile_components/profile_matrix';
import ProfileMoodStatus from './pages/page_mood';
import PifagorSquare from './little_components/profile_components/profile_pifagor_square';
// import { updateProfileUserInfo } from './store/profileUserInfoSlice';
// import { useDispatch } from 'react-redux';
// import Spinner from './little_components/spinner_loading';

const CentralBlock = () => {

  // const dispatch = useDispatch()

  const [loginPopupOpen, setLoginPopupOpen] = useState(false)
  const [registerPopupOpen, setRegisterPopupOpen] = useState(false)

  const [refreshing, setRefreshing] = useState(false);
  const [startY, setStartY] = useState(null);
  const [deltaY, setDeltaY] = useState(0)
  // eslint-disable-next-line 

  const [isAtTop, setIsAtTop] = useState(false);
  const blockRef = useRef(null);

  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  // При открытии сайта обновляем нашу расцветку
  if (window.location.pathname === '/join') {
    document.documentElement.setAttribute('data-theme', 'light')
  } else {
    if (localStorage.getItem('colorTheme')) {
      document.documentElement.setAttribute('data-theme', localStorage.getItem('colorTheme'))
    } else if (isDarkMode) {
      localStorage.setItem('colorTheme', 'dark')
      document.documentElement.setAttribute('data-theme', 'dark')
    } else {
      localStorage.setItem('colorTheme', 'light')
      document.documentElement.setAttribute('data-theme', 'light')
    }
  }


  const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser)
  // dispatch(updateProfileUserInfo(authorizedUser))
  // const profileUser = useSelector(state => state.profileUserSlice.profileUser)
  // console.log(profileUser)

  const displayNonOverflow = useSelector(state => state.displayNonOverflowStatus.displayNonOverflow)

  WebSocketFunc()

  displayNonOverflow ? document.body.style.overflow = "hidden" : document.body.style.overflow = ''

  // Тянем сверху вниз для обновления страницы

  useEffect(() => {
    const handleScroll = () => {
      if (blockRef.current) {
        const blockRect = blockRef.current.getBoundingClientRect();
        setIsAtTop(blockRect.top >= 0);
      }
    };

    // Вызываем обработчик события явно после добавления обработчика
    handleScroll();

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleTouchStartToRefresh = (event) => {
    if (isAtTop) {
      setStartY(event.touches[0].clientY);
    }


  }

  const handleTouchMoveToRefresh = (event) => {
    if (startY !== null) {
      const beforeDelta = Math.round(event.touches[0].clientY - startY)
      if (beforeDelta > 0 && !displayNonOverflow) {
        setDeltaY(Math.round(event.touches[0].clientY - startY))
      }

      // Пользователь оттянул вниз на достаточное расстояние
      if (deltaY > 100) {
        setRefreshing(true);
      }

    }
  };

  const handleTouchEndToRefresh = () => {
    if (refreshing && isAtTop && !displayNonOverflow) {
      window.location.reload()
    }

    setStartY(null);
    setRefreshing(false);
    setDeltaY(0)
  }



  return (
    <BrowserRouter>

      {/* Центральный контейнер */}
      <div className="central_container sm:flex-col-reverse sm:overflow-x-hidden !gap-2"
        onTouchStart={handleTouchStartToRefresh}
        onTouchMove={handleTouchMoveToRefresh}
        onTouchEnd={handleTouchEndToRefresh}
      >


        {/* Навигационная панель */}
        <Navigation loginList={[setLoginPopupOpen, loginPopupOpen]} registerList={[setRegisterPopupOpen, registerPopupOpen]} />

        {/* Сам центральный блок */}
        <div className={`flex flex-col gap-2 sm:w-full w-[800px] h-fit ${window.location.pathname !== '/join' ? 'pt-[44px]' : 'pt-[30px]'} pb-[44px] sm:touch-pan-y`}
          ref={blockRef}
        >

          {refreshing ? (
            // <Spinner />
            <div className="w-full h-2 bg-green-400 rounded-full" style={{ width: `${100}%` }}>
            </div>
          ) : (
            deltaY !== 0
              ?
              <div className='flex items-center justify-center z-[100]'>
                <div className="w-full h-2 bg-green-400 rounded-full" style={{ width: `${deltaY}%` }}></div>
              </div>
              : null
          )}

          <Routes>

            <Route exact path="/" element={authorizedUser?.username ? <Posts pageName='all_posts_list' /> : <Navigate to="/join" />} />

            <Route exact path="/following" element={<Posts pageName='following' />} />
            <Route exact path="/saved" element={<Posts pageName='saved' />} />

            <Route exact path="/messages" element={<MessagesPage pageName='messages' />} />
            <Route exact path="/friends" element={<FriendsPage pageName='friends' />} />
            <Route exact path="/options" element={<OptionsPage pageName='options' />} />

            <Route exact path="/:username" element={<ProfilePageContainer />} />

            <Route exact path={`/:username/matrix`} element={< ProfileMatrix />} />
            <Route exact path={`/:username/planets`} element={<Zodiac />} />
            <Route exact path={`/:username/mood`} element={<ProfileMoodStatus />} />
            <Route exact path={`/:username/pifagor`} element={<PifagorSquare />} />

            <Route exact path="/join" element={<Commercial />} />

            <Route exact path="/about" element={<About />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="/page_not_exists" element={<NotFoundPage />} />

          </Routes>
        </div>

        {loginPopupOpen ? <PopupContainer t={t} choosePopup={'login_popup'} setPopupOpen={setLoginPopupOpen} additionInfo={''} /> : null}
        {registerPopupOpen ? <PopupContainer t={t} choosePopup={'register_popup'} setPopupOpen={setRegisterPopupOpen} additionInfo={''} /> : null}


        {/* LOGO */}
        {window.location.pathname !== '/join'
          ?
          <Link to='about'>
            <div className='z-[10] transpatent_bg h-11 w-full fixed top-0 touch_screen_non_selectable sm:flex-row cursor-pointer sm:flex hidden items-center justify-center  sm:gap-1 border-b-[1px] border_color shadow-lg'>
              <img className="pointer-events-none w-11 h-11 sm:w-15 sm:h-15 hover:animate-pulse" src="/images/logo.png" alt="logo" />
              <p className="">Destiny Matrix Net</p>
            </div>
          </Link>
          : null
        }


      </div>
    </BrowserRouter>
  )
};

export default withTranslation()(CentralBlock);
